import api from "../httpClient.js";

export default {
  async getUserClassificacoes() {
    const response = await api.get("/usersclassificacoes");
    return response.data.userclassificacoes;
  },

  async putUserClassificacoes(id, descricao) {
    return api.put(`/usersclassificacoes/${id}`, {
      descricao
    });
  },

  async postUserClassificacoes(user_id, classificacao_id) {
    return api.post("/usersclassificacoes/add", {
      user_id,
      classificacao_id
    });
  }
};
