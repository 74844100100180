<template>
  <v-container v-if="vueUsuario">
    <template v-if="!loading">
      <PageToolbar voltar dark icon="person_pin" :title="usuario.name" />

      <v-card class="mt-4">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="2"
              class="pt-8 lightgray"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                  : ''
              "
            >
              <div class="d-flex justify-center align-center">
                <v-hover v-slot:default="{ hover }">
                  <v-avatar
                    size="120"
                    style="box-shadow: 0px 0px 0px 3px rgba(32,140,110,1);"
                  >
                    <v-img
                      :src="
                        usuario.avatar
                          ? apiUrl + usuario.avatar
                          : require('@/assets/avatar_placeholder.png')
                      "
                    >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                          style="height: 34px; "
                        >
                          <v-btn
                            depressed
                            text
                            x-small
                            dark
                            class="mt-1"
                            @click="dialogImages = true"
                          >
                            {{ $tc("global.alterar") }}
                          </v-btn>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-avatar>
                </v-hover>
              </div>
              <v-card outlined class="mt-6">
                <v-tabs v-model="tab" vertical>
                  <v-tab class="caption font-weight-bold justify-start">
                    <v-icon left>info</v-icon>
                    {{ $tc("global.dados-gerais") }}
                  </v-tab>
                  <v-tab
                    class="caption font-weight-bold justify-start"
                    v-if="vuePermissoesUsuario"
                  >
                    <v-icon left>assignment</v-icon>
                    {{ $tc("pages.permissao", 2) }}
                  </v-tab>
                </v-tabs>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-tabs-items v-model="tab">
                <!-- Dados Gerais -->
                <v-tab-item>
                  <v-form v-model="valid" ref="form">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4" v-if="isAdmin">
                          <v-card
                            outlined
                            height="40px"
                            class="d-flex justify-space-between align-center"
                          >
                            <v-card-text class="body-1 py-0"
                              >{{ $tc("columns.adm") }}:</v-card-text
                            >
                            <v-switch
                              class="ma-0"
                              inset
                              hide-details
                              v-model="usuario.is_admin"
                            ></v-switch>
                          </v-card>
                        </v-col>

                        <v-col cols="12" :md="isAdmin ? '8' : '12'">
                          <v-text-field
                            v-model="usuario.email"
                            :rules="[rules.email]"
                            label="Email"
                            type="email"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model.lazy="usuario.name"
                            :label="$tc('columns.nomecompleto')"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="usuario.whats"
                            label="Whats"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="usuario.celular"
                            :label="$tc('columns.celular')"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            :append-icon="
                              show ? 'visibility' : 'visibility_off'
                            "
                            name="senha"
                            :label="$tc('columns.senha')"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            dense
                            hide-details
                            outlined
                            v-model="usuario.password"
                            class="input-group--focused"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            :append-icon="
                              show ? 'visibility' : 'visibility_off'
                            "
                            name="confirmarSenha"
                            :label="$tc('columns.confirmsenha')"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            dense
                            hide-details
                            outlined
                            v-model="usuario.password_confirmation"
                            class="input-group--focused"
                          ></v-text-field>
                        </v-col>

                        <!-- Classificação -->
                        <v-col cols="12" md="6">
                          <v-card outlined>
                            <v-card-title class="subtitle-1 lightgray py-3">
                              {{ $tc("pages.classificacao", 2) }}
                              <v-spacer></v-spacer>
                              <v-btn
                                @click="dialog = !dialog"
                                depressed
                                dark
                                dense
                                color="toolbar"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider
                              v-if="
                                userclassificacoes && userclassificacoes.length
                              "
                            ></v-divider>
                            <v-card-text
                              v-if="
                                userclassificacoes && userclassificacoes.length
                              "
                              class="py-4"
                            >
                              <v-chip
                                v-for="(classificacao,
                                index) in userclassificacoes"
                                :key="index"
                                :color="classificacao.cor"
                                class="ma-1"
                                label
                                text-color="white"
                                close
                                close-icon="delete"
                                @click:close="
                                  delUserClassificacao(classificacao.id)
                                "
                              >
                                <v-icon left v-if="classificacao.icone">
                                  {{ classificacao.icone }}</v-icon
                                >
                                <v-icon left v-else>label</v-icon>
                                {{ classificacao.descricao }}
                              </v-chip>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-dialog v-model="dialog" width="100%" max-width="400">
                          <v-card
                            :loading="loadingClassificacao"
                            :disabled="loadingClassificacao"
                          >
                            <v-card-title class="subtitle-1 lightgray">
                              {{ $tc("class.addclass") }}
                              <v-spacer></v-spacer>
                              <v-btn icon @click="dialog = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="py-10">
                              <v-select
                                v-model="classificacao_id"
                                :items="classificacoes"
                                clearable
                                item-text="descricao"
                                item-value="id"
                                filled
                                dense
                                hide-details
                                :label="$tc('pages.classificacao', 2)"
                              ></v-select>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="toolbar"
                                depressed
                                dark
                                small
                                @click="createUserClassificacoes"
                              >
                                {{ $tc("global.add") }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <!--  Depositos -->
                        <v-col cols="12" md="6">
                          <v-card outlined>
                            <v-card-title class="subtitle-1 lightgray py-3">
                              {{ $tc("pages.deposito", 2) }}
                              <v-spacer></v-spacer>
                              <v-btn
                                @click="
                                  dialogUserDeposito = !dialogUserDeposito
                                "
                                depressed
                                dark
                                dense
                                color="toolbar"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider
                              v-if="userDepositos && userDepositos.length"
                            ></v-divider>
                            <v-card-text
                              v-if="userDepositos && userDepositos.length"
                              class="py-4"
                            >
                              <v-chip
                                v-for="(deposito, index) in userDepositos"
                                :key="index"
                                class="ma-1"
                                label
                                close
                                close-icon="delete"
                                @click:close="delUserDeposito(deposito.id)"
                              >
                                <v-icon left>store</v-icon>
                                {{ deposito.deposito }}
                              </v-chip>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-dialog
                          v-model="dialogUserDeposito"
                          width="100%"
                          max-width="400"
                        >
                          <v-card
                            :loading="loadingUserDeposito"
                            :disabled="loadingUserDeposito"
                          >
                            <v-card-title class="subtitle-1 lightgray">
                              {{
                                $tc("global.add") + " " + $tc("pages.deposito")
                              }}
                              <v-spacer></v-spacer>
                              <v-btn icon @click="dialogUserDeposito = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="py-10">
                              <v-select
                                v-model="userDeposito.deposito_id"
                                :items="depositosNotEqual"
                                clearable
                                item-text="descricao"
                                item-value="id"
                                filled
                                dense
                                hide-details
                                :label="$tc('pages.deposito', 2)"
                              ></v-select>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="toolbar"
                                depressed
                                dark
                                small
                                @click="criarUserDeposito"
                              >
                                {{ $tc("global.add") }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-tab-item>

                <!-- Permissoes -->
                <v-tab-item v-if="vuePermissoesUsuario">
                  <UsuarioPermissoes :usuarioId="usuarioId" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <SelectImageModal
        :dialogImages.sync="dialogImages"
        origem="usuarios"
        :origem_id="id"
      >
        <template v-slot:saveSelected>
          <v-spacer></v-spacer>
          <v-fade-transition>
            <v-btn
              v-if="imagemSelecionada"
              depressed
              dark
              small
              color="toolbar"
              @click="updateAvatar(imagemSelecionada.path_original)"
            >
              Salvar
              <v-icon>save</v-icon>
            </v-btn>
          </v-fade-transition>
        </template>
      </SelectImageModal>

      <v-btn
        @click.prevent="updateUsuario(id)"
        fixed
        right
        bottom
        fab
        dark
        color="buttons"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
      >
        <v-icon>save</v-icon>
      </v-btn>
    </template>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import {
  getUserClassificacoes,
  putUsuario,
  getUsuario,
  fetchDepositosNotEqual,
  fetchUserDepositos,
  postUserDeposito,
  uploadImage,
  getImages,
  deleteUserClassificacao,
  deleteUserDeposito,
} from "@/services/api/usuarios.api.js";
import { getClassificacoesNotEqual } from "@/services/api/classificacoes.api.js";
import UserClassificacoesApi from "@/services/api/users-classificacoes.js";
import { fetchDepositos } from "@/services/api/produtos.api.js";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Usuario",
  props: ["usuarioId"],
  components: {
    UsuarioPermissoes: () => import("./components/UsuarioPermissoes.vue"),
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  data: () => ({
    usuario: {},
    classificacao_id: null,
    classificacoes: null,
    userclassificacoes: null,
    loadingClassificacao: false,
    tab: null,
    show: false,
    dialog: false,
    valid: false,
    loading: false,
    notFound: false,
    original: {},
    rules: {
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "O e-mail precisa ser valido!";
      },
    },
    loadingUserDeposito: false,
    dialogUserDeposito: false,
    userDepositos: [],
    depositos: [],
    depositosNotEqual: [],
    userDeposito: {},
    selectedFile: null,
    apiUrl: null,
    images: [],
    avatar: "",
    dialogImages: false,
    carregandoImagem: false,
  }),
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
    }),
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueUsuario() {
      return this.getAccess("vueUsuario", "visualizar");
    },
    vuePermissoesUsuario() {
      return this.getAccess("vuePermissoesUsuario", "visualizar");
    },
    id() {
      return this.usuarioId;
    },
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    createUserClassificacoes() {
      this.loadingClassificacao = true;
      UserClassificacoesApi.postUserClassificacoes(
        this.usuarioId,
        this.classificacao_id
      )
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.addsucesso"));
            this.dialog = false;
            this.M_getUserClassificacoes();
            this.getClassificacoes();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingClassificacao = false;
        });
    },
    M_getUserClassificacoes() {
      getUserClassificacoes(this.usuarioId)
        .then((response) => {
          this.userclassificacoes = response;
        })
        .catch((error) => console.log(error));
    },
    updateUsuario(id) {
      if (this.lodash.isEqual(this.usuario, this.original)) {
        this.$toast.error(this.$tc("msg.fazermudanca"));
      } else {
        putUsuario(id, this.usuario)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success(this.$tc("msg.atualizadosucesso"));
              this.original = { ...this.usuario };
              if (this.user_id === this.id) {
                this.$store.dispatch("Usuario/getUsuario");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getClassificacoes() {
      getClassificacoesNotEqual(this.usuarioId)
        .then((response) => {
          this.classificacoes = response;
        })
        .catch((error) => console.log(error));
    },
    getDepositos() {
      fetchDepositos()
        .then((response) => {
          this.depositos = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepositosNotEqual() {
      fetchDepositosNotEqual(this.id)
        .then((response) => {
          this.depositosNotEqual = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserDepositos() {
      fetchUserDepositos(`?user_id=${this.id}`)
        .then((response) => {
          this.userDepositos = response;
        })
        .catch((error) => console.log(error));
    },
    criarUserDeposito() {
      this.userDeposito.user_id = this.id;
      this.loadingUserDeposito = true;
      this.$Progress.start();
      postUserDeposito(this.userDeposito)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.addsucesso"));
            this.userDeposito = {};
            this.getUserDepositos();
            this.getDepositosNotEqual();
            this.dialogUserDeposito = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loadingUserDeposito = false;
          this.$Progress.finish();
        });
    },
    findDeposito(id) {
      let nome = "";
      this.depositos.forEach((item) => {
        if (item.id === id) {
          nome = item.descricao;
        }
      });
      return nome;
    },
    uploadImage(fd) {
      if (fd) {
        this.$Progress.start();
        this.carregandoImagem = true;
        uploadImage(fd)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success(this.$tc("msg.addsucesso"));
              this.$Progress.finish();
              this.getUserImages();
              this.carregandoImagem = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
            this.carregandoImagem = false;
          });
      }
    },
    onFileSelected(event, id) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile, this.selectedFile.name);
      fd.append("user_id", id);
      this.uploadImage(fd);
    },
    fetchUser() {
      this.loading = true;
      this.$Progress.start();
      getUsuario(this.id)
        .then((response) => {
          this.usuario = response;
          this.original = this.lodash.cloneDeep(response);
          this.loading = false;
          this.$Progress.finish();
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.usuario.name
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.usuario.name);
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
    getUserImages() {
      getImages(this.id)
        .then((response) => {
          this.images = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAvatar(image) {
      this.usuario.avatar = image;
      this.updateUsuario(this.id);
      this.dialogImages = false;
    },
    delUserDeposito(id) {
      this.$Progress.start();
      deleteUserDeposito(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(this.$tc("msg.removidosucesso"));
            this.getUserDepositos();
            this.getDepositosNotEqual();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    delUserClassificacao(id) {
      this.$Progress.start();
      deleteUserClassificacao(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(this.$tc("msg.removidosucesso"));
            this.M_getUserClassificacoes();
            this.getClassificacoes();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
  },
  mounted() {
    if (this.vueUsuario) {
      this.getUserImages();
      if (process.env.VUE_APP_API_URL) {
        this.apiUrl = process.env.VUE_APP_API_URL;
      }
      if (this.id) {
        this.fetchUser();
      } else {
        this.notFound = "El usuario no ha sido encontrado";
      }
      this.getClassificacoes();
      this.getDepositos();
      this.getDepositosNotEqual();
      this.getUserDepositos();
      this.M_getUserClassificacoes();
    }
  },
  created() {},
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
